<template>
  <v-container
    fluid
    class="grey lighten-2"
  >
    <v-layout
      justify-center
      align-center
      class="mt-5 pt-5"
    >
      <v-flex
        xs10
        sm6
        md4
      >
        <v-card
          class="d-flex mt-5 flex-column justify-center align-center"
        >
          <v-avatar
            color="primary"
          >
            <v-icon
              color="white"
              large
            >
              mdi-truck
            </v-icon>
          </v-avatar>
          <v-card-title>
            <v-icon
              large
              color="red"
              class="mt-4 mr-2 mb-2"
            >
              mdi-close-octagon
            </v-icon>
            <h2
              class="text-center mt-4 mb-2"
            >
              Забыли пароль?
            </h2>
          </v-card-title>
          <v-card-text>
            <v-layout justify-center>
              <v-flex class="my-1">
                <p
                  class="text-center ma-2"
                >
                  Введите логин вашего аккаунта, вам будет выслана ссылка для восстановление пароля
                </p>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex class="my-1">
                <v-text-field
                  v-model="email"
                  label="Email"
                />
              </v-flex>
            </v-layout>
            <v-layout
              column
              class="mt-1"
            >
              <v-flex
                xs2
                class="d-flex justify-center"
              >
                <v-btn
                  :loading="loading"
                  :disabled="!email"
                  color="primary"
                  @click="send"
                >
                  Отправить
                </v-btn>
                <v-btn
                  class="ml-3"
                  @click="$router.push({name: 'login'})"
                >
                  Назад
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import * as usersApi from "../../backend/usersApi.js";
export default {
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    async send() {
      this.loading = true;
      const email = this.email.trim();
      try {
        const response = await usersApi.sendEmail(email);
        if (response) {
          this.showInfo("Письмо с ссылкой для восстановления пароля отправлено на email");
        }
      } catch {
        this.showError("Ошибка при отправке письма с ссылкой для восстановления пароля");
      }
      finally {
        this.email = "";
        this.loading = false;
      }
    },
  },
};
</script>
